@import '~bravely/bravely';
//==== transitions = start
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
//==== transitions = end
.custom-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > label {
    color: $colorBlueDarkest;
    font-weight: $font-weight-normal;
    font-size: 16px;
  }
  .close-modal-btn {
    background-color: transparent;
    transform: translate(-3px, 10px);
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  & .hideDivider {
    border-bottom: none !important;
  }
}

.custom-modal-body {
  display: flex;
  flex-direction: column;
  padding: 10px 28px;
  color: $colorBlueNeutral;

  & > label {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: $font-weight-normal;
    color: $colorBlueDarkest;
    white-space: pre-wrap;

    & > .optional {
      font-style: italic;
      color: $colorEeyoreMist;
    }
    &.untag-modal-label {
      margin-bottom: 0;
    }
  }
}

.custom-modal-footer {
  width: 100%;
  height: 87px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  padding-left: 28px;
  padding-right: 28px;
  border-top: solid 1px $colorGreyWhite;
  &.untag-modal {
    padding-right: 15px;
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .back {
      transform: translateY(16%);
      color: $colorBrightPink;
      font-size: 12px;
      font-weight: 500;
      & > span > svg {
        transform: translate(-65%, 12%);
      }
    }
    button {
      padding: 9px;
      background-color: $colorWhitePure;
      color: $colorBrightPink;
      border-radius: 4px;
      font-size: 14px;
      font-weight: $font-weight-light !important;
      border: none;
      min-width: 108px;
      height: 35px;
      text-align: center;
      line-height: 1;
      &:hover {
        cursor: pointer;
      }
      &.action {
        background-color: $colorBlueNeutral;
        color: $colorWhitePure;
        margin-right: 28px;
        &:disabled {
          cursor: not-allowed;
          background-color: $colorGreyMisty;
        }
      }
      &.dismiss {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .spinner {
      transform: translate(50%, 35%);
    }
  }
}
