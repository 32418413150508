@import '~bravely/bravely';

$mobile-button-height: 45px;
$mobile-sticky-padding-bottom: 16px;

.Button {
  @include action-button();
  min-width: 180px;

  @media ($mobile-max) {
    display: block;
    width: 100%;
    max-width: $mobile-max-content-width - ($mobile-content-padding * 2);
    margin: 0 auto;
  }
}

@media ($mobile-max) {
  .floatingContainer {
    position: fixed;
    bottom: $mobile-sticky-padding-bottom;
    left: 0;
    width: 100%;
    padding: 0 10px;
  }

  .stickToBottomContainer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 15px;
    padding: 0 10px;
  }

  :global body.with-focus :local .stickToBottomContainer {
    position: static;
    margin-top: 20px;
  }

  .floating {
    margin: 0 auto;
    max-width: 400px;
  }
}

.floatingPadding {
  padding-bottom: 70px;
}
